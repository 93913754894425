export const environment = {
  production: true,
  baseUrl: 'https://newreportcenter.finra.org/reportcenter/',
  ddwaBaseUrl: 'https://rc-services.ddwa.finra.org/',
  onPremRcUrl: 'https://reportcenter.finra.org/scrc/index.do',
  ewsLogoutUrl: 'https://ews.finra.org/auth/ews_logout.html',
  ewsExpirationTimeUrl: 'https://ews.finra.org/rds/restful-services/session/expirationTime',
  ewsLoginUrl: 'https://ews.finra.org/auth/ews_logon.jsp?aa_param',
  isEwsReportCenter: 'true',
  issoBaseUrl: 'https://isso-assets.finra.org/reportcenter-isso/',

  notificationsAnnouncementUrl: 'https://notifications-api.finra.org',
  beastClickStreamUrl: 'https://beast-api.finra.org/events',
  beastDetailsFormatVersion: '1.0.0',
  beastEnvironment: 'prod',
  env: 'prod',
  isCloudfront: 'true',
  fipAuthPageUrl: 'https://ews.fip.finra.org/fip',
  fipApiUrl: 'https://ews.finra.org/auth/rest/v2/sessions/info',
};
